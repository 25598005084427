.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 98vh;
  width: 99vw;
  max-width: 99vw;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  height: 48rem;
  width: 75rem;
  max-height: 100vh;
  max-width: 100vw;
  margin-top: 10;
  margin-bottom: 0;
  padding: 7.5px;
}

.main {
  background-color: rgb(29, 29, 29);
  height: 94%;
  width: 96.5%;
  border-radius: 0.6rem;
  position: absolute;
}

.upper,
.lower {
  height: 15rem;
  width: 17rem;
  position: absolute;
  background: linear-gradient(-45deg, #b0330d, #e73c7e, #23a6d5, #23d5ab);
  background-size: 200% 200%;
  transition: all 0.3s ease-in-out;
}

.upper {
  left: 0;
  top: 0;
  border-top-left-radius: 0.6rem;
}
.lower {
  right: 0;
  bottom: 0;
  border-bottom-right-radius: 0.6rem;
}

.hovered .upper,
.hovered .lower {
  background-position: 100% 50%;
}

@media screen and (min-width: 500px) {
  .mainContainer .container {
    padding: 20px;
  }
}
@media only screen and (max-width: 500px) {
  .upper,
  .lower {
    height: 10rem;
    width: 10rem;
  }
  .mainContainer {
    flex-direction: column;
    height: 100vh;
    margin-bottom: 2rem;
  }
  .container {
    margin-top: 0;
    margin-bottom: 10rem;
    padding: 0;
  }
  .lower {
    display: none;
  }
  .main {
    bottom: 0;
  }
  .main {
    background-color: rgb(29, 29, 29);
    top: 0.8rem;
    left: 0.8rem;
  }
}
@media only screen and (max-width: 500px) {
  .mainContainer {
    height: auto;
  }
}
@media only screen and (max-width: 900px) {
  .lower {
    display: none;
  }
}
