.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}
.nav,
.footer {
  width: 100%;
}

@media only screen and (max-width: 500px) {
  .mainContainer {
    flex-direction: column;
    height: 100vh;
  }
  .content {
    display: block;
    height: 1000px;
  }
  .footer {
    display: block;
    bottom: 0;
  }
}
