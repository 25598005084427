.navMain {
  height: 7rem;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 2rem 1rem 0 1rem;
}
.navbar {
  padding-bottom: 2rem;
  justify-content: space-between;
}
li a {
  display: inline;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}
.logo {
  height: 5rem;
  transition: all 0.2s ease-in-out;
}
.logo:hover {
  transform: scale(1.1);
}
.rightContainer {
  display: flex;
  justify-content: center;
}
a {
  color: #949494;
  transition: all 0.2s ease;
}
a:hover {
  color: #c4c2c2;
}
a:hover svg {
  transform: scale(1.2);
}
svg {
  height: 24px;
  width: 24px;
}
@media only screen and (max-width: 500px) {
  .logo {
    height: 4rem;
  }
  ul {
    padding: 1rem 0.7rem;
  }
  li a {
    padding: 0.65rem;
  }
  svg {
    height: 22px;
    width: 22px;
  }
}
