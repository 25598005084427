.footer {
  display: flex;
  padding-right: 2rem;
  padding-bottom: 1rem;
  padding-top: 3rem;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}
@media only screen and (max-width: 500px) {
  .footer {
    height: 250px;
    bottom: 0;
    align-items: center;
  }
}
