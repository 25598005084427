.mainConatiner {
  display: flex;
  justify-content: space-between;
  -webkit-font-smoothing: subpixel-antialiased;
  box-sizing: border-box;
  padding-left: 5rem;
}
.projects > ul {
  padding-bottom: 2rem;
}
.links > ul {
  margin-bottom: 0;
  justify-content: flex-start;
}
.projectName,
.open {
  margin-right: 2rem;
}
.open {
  cursor: pointer;
}
.open > a {
  color: #b6b6b6;
  font-weight: 500;
}
.open:hover {
  color: white;
  transform: scale(1.02);
}
.leftConatainer {
  margin-right: 3rem;
  width: 70%;
}

.title {
  font-size: 6rem;
  font-weight: 200;
  margin-bottom: 2rem;
}
.projecttitle {
  font-size: 3.5rem;
  font-weight: 200;
  margin-bottom: 2rem;
}

.content {
  font-size: 0.9rem;
  font-weight: 300;
  margin-left: 2rem;
  margin-right: 2rem;
}

.contentContainer {
  display: flex;
  align-items: stretch;
}

.side {
  width: 5px;
  background: linear-gradient(-45deg, #b0330d, #e73c7e, #23a6d5, #23d5ab);
  background-size: 200% 200%;
  transition: all 0.3s ease;
}

.contentContainer:hover .side,
.open:hover .linkline {
  background-position: 100% 50%;
}

.rightContainer {
  width: 30%;
}

.project {
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 2rem;
}
.projects > ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2rem;
}

li {
  text-decoration: none;
  transition: all 0.2s;
  font-size: 0.95rem;
  cursor: pointer;
}

.projectName:hover {
  transform: scale(1.1);
}

.projectName {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  transition: all 0.1s ease-in-out;
}

.line {
  height: 2px;
  margin-top: 3px;
  background: linear-gradient(-45deg, #b0330d, #e73c7e, #23a6d5, #23d5ab);
  background-size: 160% 160%;
}
.linkline {
  height: 2px;
  margin-top: 0.4rem;
  background: linear-gradient(-45deg, #b0330d, #e73c7e, #23a6d5, #23d5ab);
  background-size: 160% 160%;
}

.projectName:hover .line {
  background-position: 100% 50%;
}

.projectContainer {
  position: relative;
  transition: all 0.1s ease-in-out;
}

.upper {
  background: linear-gradient(-45deg, #b0330d, #e73c7e, #23a6d5, #23d5ab);
  background-size: 200% 200%;
  height: 100px;
  width: 100px;
  position: absolute;
  border-top-left-radius: 0.5rem;
  transition: all 0.3s ease-in-out;
}

.projects {
  position: absolute;
  top: 2px;
  left: 2px;
  background-color: rgb(29, 29, 29);
  border-top-left-radius: 0.5rem;
  padding-left: 1rem;
}

.projectContainer:hover .upper {
  background-position: 100% 50%;
}

@media only screen and (max-width: 900px) {
  .mainConatiner {
    flex-direction: column;
  }
  .rightContainer {
    margin-top: 3rem;
  }
}

@media only screen and (max-width: 500px) {
  .projects > ul {
    margin-top: 1rem;
  }
  .mainConatiner {
    flex-direction: column;
    padding-left: 2rem;
    margin-top: 3rem;
  }
  .rightContainer {
    margin-top: 3rem;
    width: 100%;
    height: 100%;
  }
  .leftConatainer {
    width: 90%;
  }
  .title {
    font-size: 2.9rem;
    font-weight: 300;
    margin-bottom: 2rem;
  }
  .content {
    margin-left: 1rem;
    margin-right: 0.6rem;
    line-height: 1.35rem;
  }
  .projects {
    width: 90%;
    margin-bottom: 5rem;
  }
  .projectContainer {
    position: relative;
    transition: all 0.1s ease-in-out;
  }
  .wrapper {
    display: block;
    position: static;
  }
  .projectName {
    font-weight: 300;
  }

  .side {
    width: 10px;
  }
  .open {
    margin-right: 1.3rem;
  }
}
@keyframes slidein {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.9;
  }
  75% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
